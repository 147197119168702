import React from 'react';
import { NavLink } from 'react-router-dom';
import ConversationListSkeleton from './ConversationListSkeleton';
import { Icon } from '@progress/kendo-react-common';
import { useInternationalization } from '@progress/kendo-react-intl';

export interface ConversationListProps {
  heading?: string;
  noMessages?: string;
  conversationList?: any;
  isConversationListFetched?: boolean | null;
}

const ConversationList = ({
  heading,
  conversationList,
  isConversationListFetched,
  noMessages,
}: ConversationListProps) => {
  const intl = useInternationalization();

  const conversations = conversationList.map((conversation: any) => {
    const conversationGuid = conversation.conversationGuid;
    const conversationTypeId = conversation.conversationTypeId;
    const resourceTypeId = conversation.resourceTypeId;
    const resourceGuid = conversation.resourceName;
    const conversationTitle = conversation.friendlyName;
    const unreadMessages = conversation.myUnreadMessages;

    const lastActivityDate = conversation.lastActivityDate;
    let date =
      !!lastActivityDate &&
      intl.formatDate(new Date(lastActivityDate as string), {
        date: 'short',
      });
    const isNewMessages = unreadMessages > 0;
    const taskId = resourceGuid;

    let goToUrl = '';
    const goToTaskUrl = `/user/tasks/${taskId}?conversationGuid=${conversationGuid}`; // redirect to task details page with conversation
    const goToConversationUrl = `/conversations/${conversationGuid}`; // redirect to dedicated conversation page

    if (conversationTypeId === 1 && resourceTypeId === 1) {
      goToUrl = goToTaskUrl;
    } else {
      goToUrl = goToConversationUrl;
    }

    // let conversationType;
    // switch (conversationTypeId) {
    //   case 1:
    //     // resource/task
    //     conversationType = 'Resource';
    //     break;
    //   case 2:
    //     // topic
    //     conversationType = 'Topic';
    //     break;
    //   case 3:
    //     // direct
    //     conversationType = 'Direct';
    //     break;
    //   default:
    //   // code
    // }

    return (
      <NavLink
        className={`${
          isNewMessages ? 'new-message' : ''
        } list-group-item list-group-item-action`}
        key={conversationGuid}
        to={goToUrl}
      >
        {isNewMessages ? (
          <Icon className='far fa-envelope' />
        ) : (
          <Icon className='fal fa-envelope-open-text' />
        )}

        <span className='list-item-text'>{conversationTitle}</span>

        {/* <span>{conversationType}</span> */}

        <span className='list-item-date'>{date}</span>
      </NavLink>
    );
  });

  return (
    <>
      {isConversationListFetched ? (
        conversations.length > 0 ? (
          <>
            <h6>{heading}</h6>
            <div className='list-group k-widget k-d-flex conversations-list'>
              {conversations}
            </div>
          </>
        ) : (
          <p>{noMessages}</p>
        )
      ) : (
        <ConversationListSkeleton />
      )}
    </>
  );
};

export default ConversationList;

import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../app/store';
import { getAxiosConfig } from '../../../helpers/utils';
import authService from '../../api-authorization/AuthorizeService';
import { Holding } from './types';

let contactGuid: string;
let portfolioId: string;

const state = (state: RootState) => state;
const selectedContactGuid = (state: RootState) =>
  state.representations.selectedAccount?.contactGuid || state.user.contactGuid;
const selectedPortfolioId = (state: RootState) =>
  state.portfolios.selectedPortfolioId;

interface PortfolioObjectProps {
  userPortfolios: Holding[] | null;
  userPortfoliosFetched: boolean | null;
  userPortfoliosError: string | null;
  hasUserPortfolios: boolean | null;
  selectedPortfolioId: string | null;
  selectedPortfolioData: {
    [key: string]: {
      [key: string]: {
        holdings?: any;
        holdingsFetched?: boolean;
        holdingsError?: string;
        timeSeries?: any;
        timeSeriesFetched?: boolean;
        timeSeriesError?: string;
        transactions?: any;
        transactionsFetched?: boolean;
        transactionsError?: string;
      };
    };
  };
  indices?: any;
  indicesFetched?: boolean;
  indicesError?: string;
}

const initialState: PortfolioObjectProps = {
  userPortfolios: null,
  userPortfoliosFetched: null,
  userPortfoliosError: null,
  hasUserPortfolios: null,
  selectedPortfolioId: null,
  selectedPortfolioData: {},
};

// Gateway API version - getting Portfolios from Gateway API
export const fetchUserPortfolios = createAsyncThunk(
  'fetchUserPortfolios',
  async (dataObject: any, thunkAPI) => {
    const token = await authService.getAccessToken();
    const url = dataObject.url;
    const body = dataObject.graphqlQuery;
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.post(url, body, config);
      const data = response.data.data.holdings;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

// Gateway API version - getting Portfolio Holdings Data from Gateway API
export const fetchSelectedPortfolioHoldingsData = createAsyncThunk(
  'fetchSelectedPortfolioHoldingsData',
  async (dataObject: any, thunkAPI) => {
    const token = await authService.getAccessToken();
    const url = dataObject.url;
    const body = dataObject.graphqlQuery;
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.post(url, body, config);
      const data = response.data.data.holdings;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

// Gateway API version - getting Portfolio TimeSeries Data from Gateway API
export const fetchSelectedPortfolioTimeSeriesData = createAsyncThunk(
  'fetchSelectedPortfolioTimeSeriesData',
  async (dataObject: any, thunkAPI) => {
    const token = await authService.getAccessToken();
    const url = dataObject.url;
    const body = dataObject.graphqlQuery;
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.post(url, body, config);
      const data = response.data.data.timeSeries;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

// Gateway API version - getting Portfolio TimeSeries Data from Gateway API
export const fetchSelectedPortfolioTransactionsData = createAsyncThunk(
  'fetchSelectedPortfolioTransactionsData',
  async (dataObject: any, thunkAPI) => {
    const token = await authService.getAccessToken();
    const url = dataObject.url;
    const body = dataObject.graphqlQuery;
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.post(url, body, config);
      const data = response.data.data.transactions;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

// Gateway API version - getting Portfolio Indices Data from Gateway API
export const fetchIndicesData = createAsyncThunk(
  'fetchIndicesData',
  async (dataObject: any, thunkAPI) => {
    const token = await authService.getAccessToken();
    const url = dataObject.url;
    const body = dataObject.graphqlQuery;
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.post(url, body, config);
      const data = response.data.data.indices;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const portfoliosSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setPortfoliosFetched: (state, action) => {
      state.userPortfoliosFetched = action.payload;
    },
    setSelectedPortfolioId: (state, action) => {
      state.selectedPortfolioId = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUserPortfolios.pending, (state, action) => {
        // console.log('[PORTFOLIOS] LOADING');
        state.userPortfoliosFetched = false;
      })
      .addCase(fetchUserPortfolios.fulfilled, (state, action) => {
        // console.log('[PORTFOLIOS] FULFILLED');
        state.userPortfolios = action.payload;
        if (action.payload.length > 0) {
          state.hasUserPortfolios = true;
        } else {
          state.hasUserPortfolios = false;
        }
        state.userPortfoliosFetched = true;
      })
      .addCase(fetchUserPortfolios.rejected, (state, action: any) => {
        // console.log('[PORTFOLIOS] REJECTED', action);
        state.userPortfoliosFetched = true;
        state.userPortfoliosError = action?.payload?.message;
      })

      .addCase(fetchSelectedPortfolioHoldingsData.pending, (state, action) => {
        // console.log('[HOLDINGS] LOADING');
        const selectedPortfolioData = state.selectedPortfolioData;
        contactGuid = action.meta.arg.selectedContactGuid;
        portfolioId = action.meta.arg.portfolioId;

        const newSelectedPortfolioData: any = {
          ...state,
          selectedPortfolioData: {
            ...state.selectedPortfolioData,
            [contactGuid]: {
              ...selectedPortfolioData[contactGuid],
              [portfolioId]: {
                holdingsFetched: false,
              },
            },
          },
        };

        return newSelectedPortfolioData;
      })
      .addCase(
        fetchSelectedPortfolioHoldingsData.fulfilled,
        (state, action) => {
          // console.log('[HOLDINGS] FULFILLED');
          const selectedPortfolioData = state.selectedPortfolioData;
          contactGuid = action.meta.arg.selectedContactGuid;
          portfolioId = action.meta.arg.portfolioId;

          const newSelectedPortfolioData: any = {
            ...state,
            selectedPortfolioData: {
              ...state.selectedPortfolioData,
              [contactGuid]: {
                ...selectedPortfolioData[contactGuid],
                [portfolioId]: {
                  holdings: action.payload,
                  holdingsFetched: true,
                },
              },
            },
          };

          return newSelectedPortfolioData;
        },
      )
      .addCase(
        fetchSelectedPortfolioHoldingsData.rejected,
        (state, action: any) => {
          // console.log('[HOLDINGS] REJECTED', action);
          const selectedPortfolioData = state.selectedPortfolioData;
          contactGuid = action.meta.arg.selectedContactGuid;
          portfolioId = action.meta.arg.portfolioId;

          const newSelectedPortfolioData: any = {
            ...state,
            selectedPortfolioData: {
              ...state.selectedPortfolioData,
              [contactGuid]: {
                ...selectedPortfolioData[contactGuid],
                [portfolioId]: {
                  holdingsFetched: true,
                  holdingsError: action?.payload?.message,
                },
              },
            },
          };

          return newSelectedPortfolioData;
        },
      )

      .addCase(
        fetchSelectedPortfolioTimeSeriesData.pending,
        (state, action) => {
          // console.log('[INDICES] LOADING');
          const selectedPortfolioData = state.selectedPortfolioData;
          contactGuid = action.meta.arg.selectedContactGuid;
          portfolioId = action.meta.arg.portfolioId;

          const newSelectedPortfolioData: any = {
            ...state,
            selectedPortfolioData: {
              ...state.selectedPortfolioData,
              [contactGuid]: {
                ...selectedPortfolioData[contactGuid],
                [portfolioId]: {
                  ...selectedPortfolioData[contactGuid][portfolioId],
                  timeSeriesFetched: false,
                },
              },
            },
          };

          return newSelectedPortfolioData;
        },
      )
      .addCase(
        fetchSelectedPortfolioTimeSeriesData.fulfilled,
        (state, action) => {
          // console.log('[INDICES] FULFILLED');
          const selectedPortfolioData = state.selectedPortfolioData;
          contactGuid = action.meta.arg.selectedContactGuid;
          portfolioId = action.meta.arg.portfolioId;

          const newSelectedPortfolioData: any = {
            ...state,
            selectedPortfolioData: {
              ...state.selectedPortfolioData,
              [contactGuid]: {
                ...selectedPortfolioData[contactGuid],
                [portfolioId]: {
                  ...selectedPortfolioData[contactGuid][portfolioId],
                  timeSeries: action.payload,
                  timeSeriesFetched: true,
                },
              },
            },
          };

          return newSelectedPortfolioData;
        },
      )
      .addCase(
        fetchSelectedPortfolioTimeSeriesData.rejected,
        (state, action: any) => {
          // console.log('[INDICES] REJECTED', action);
          const selectedPortfolioData = state.selectedPortfolioData;
          contactGuid = action.meta.arg.selectedContactGuid;
          portfolioId = action.meta.arg.portfolioId;

          const newSelectedPortfolioData: any = {
            ...state,
            selectedPortfolioData: {
              ...state.selectedPortfolioData,
              [contactGuid]: {
                ...selectedPortfolioData[contactGuid],
                [portfolioId]: {
                  ...selectedPortfolioData[contactGuid][portfolioId],
                  timeSeriesFetched: true,
                  timeSeriesError: action?.payload?.message,
                },
              },
            },
          };

          return newSelectedPortfolioData;
        },
      )

      .addCase(
        fetchSelectedPortfolioTransactionsData.pending,
        (state, action) => {
          // console.log('[TRANSACTIONS] LOADING');
          const selectedPortfolioData = state.selectedPortfolioData;
          contactGuid = action.meta.arg.selectedContactGuid;
          portfolioId = action.meta.arg.portfolioId;

          const newSelectedPortfolioData: any = {
            ...state,
            selectedPortfolioData: {
              ...state.selectedPortfolioData,
              [contactGuid]: {
                ...selectedPortfolioData[contactGuid],
                [portfolioId]: {
                  ...selectedPortfolioData[contactGuid][portfolioId],
                  transactionsFetched: false,
                },
              },
            },
          };

          return newSelectedPortfolioData;
        },
      )
      .addCase(
        fetchSelectedPortfolioTransactionsData.fulfilled,
        (state, action) => {
          // console.log('[TRANSACTIONS] FULFILLED');
          const selectedPortfolioData = state.selectedPortfolioData;
          contactGuid = action.meta.arg.selectedContactGuid;
          portfolioId = action.meta.arg.portfolioId;

          const newSelectedPortfolioData: any = {
            ...state,
            selectedPortfolioData: {
              ...state.selectedPortfolioData,
              [contactGuid]: {
                ...selectedPortfolioData[contactGuid],
                [portfolioId]: {
                  ...selectedPortfolioData[contactGuid][portfolioId],
                  transactions: action.payload,
                  transactionsFetched: true,
                },
              },
            },
          };

          return newSelectedPortfolioData;
        },
      )
      .addCase(
        fetchSelectedPortfolioTransactionsData.rejected,
        (state, action: any) => {
          // console.log('[TRANSACTIONS] REJECTED', action);
          const selectedPortfolioData = state.selectedPortfolioData;
          contactGuid = action.meta.arg.selectedContactGuid;
          portfolioId = action.meta.arg.portfolioId;

          const newSelectedPortfolioData: any = {
            ...state,
            selectedPortfolioData: {
              ...state.selectedPortfolioData,
              [contactGuid]: {
                ...selectedPortfolioData[contactGuid],
                [portfolioId]: {
                  ...selectedPortfolioData[contactGuid][portfolioId],
                  transactionsFetched: true,
                  transactionsError: action?.payload?.message,
                },
              },
            },
          };

          return newSelectedPortfolioData;
        },
      )

      .addCase(fetchIndicesData.pending, (state, action) => {
        // console.log('[INDICES] LOADING');
        state.indicesFetched = false;
      })
      .addCase(fetchIndicesData.fulfilled, (state, action) => {
        // console.log('[INDICES] FULFILLED');
        state.indicesFetched = true;
        state.indices = action.payload;
      })
      .addCase(fetchIndicesData.rejected, (state, action: any) => {
        // console.log('[INDICES] REJECTED', action);
        state.indicesFetched = true;
        state.indicesError = action?.payload?.message;
      });
  },
});

export const { setPortfoliosFetched, setSelectedPortfolioId } =
  portfoliosSlice.actions;

export const selectUserPortfolios = (state: RootState) =>
  state.portfolios.userPortfolios;
export const selectHasUserPortfolios = (state: RootState) =>
  state.portfolios.hasUserPortfolios;
export const selectUserPortfoliosError = (state: RootState) =>
  state.portfolios.userPortfoliosError;
export const selectUserPortfoliosFetched = (state: RootState) =>
  state.portfolios.userPortfoliosFetched;

export const selectSelectedPortfolioId = (state: RootState) =>
  state.portfolios.selectedPortfolioId;

export const getSelectedPortfolioHoldings = createSelector(
  [state, selectedContactGuid, selectedPortfolioId],
  (state, contactGuid, portfolioId) => {
    const holdings =
      !!contactGuid &&
      !!portfolioId &&
      state.portfolios.selectedPortfolioData[contactGuid] !== undefined &&
      state.portfolios.selectedPortfolioData[contactGuid][portfolioId] !==
        undefined
        ? state.portfolios.selectedPortfolioData[contactGuid][portfolioId]
            .holdings
        : [];
    return holdings;
  },
);

export const getSelectedPortfolioHoldingsFetched = createSelector(
  [state, selectedContactGuid, selectedPortfolioId],
  (state, contactGuid, portfolioId) => {
    const holdingsFetched =
      !!contactGuid &&
      !!portfolioId &&
      state.portfolios.selectedPortfolioData[contactGuid] !== undefined &&
      state.portfolios.selectedPortfolioData[contactGuid][portfolioId] !==
        undefined &&
      state.portfolios.selectedPortfolioData[contactGuid][portfolioId]
        .holdingsFetched;
    return holdingsFetched;
  },
);

export const getSelectedPortfolioHoldingsError = createSelector(
  [state, selectedContactGuid, selectedPortfolioId],
  (state, contactGuid, portfolioId) => {
    const holdingsError =
      !!contactGuid &&
      !!portfolioId &&
      state.portfolios.selectedPortfolioData[contactGuid] !== undefined &&
      state.portfolios.selectedPortfolioData[contactGuid][portfolioId] !==
        undefined &&
      state.portfolios.selectedPortfolioData[contactGuid][portfolioId]
        .holdingsError;
    return holdingsError;
  },
);

export const getSelectedPortfolioTimeSeries = createSelector(
  [state, selectedContactGuid, selectedPortfolioId],
  (state, contactGuid, portfolioId) => {
    const timeSeries =
      !!contactGuid &&
      !!portfolioId &&
      state.portfolios.selectedPortfolioData[contactGuid] !== undefined &&
      state.portfolios.selectedPortfolioData[contactGuid][portfolioId] !==
        undefined
        ? state.portfolios.selectedPortfolioData[contactGuid][portfolioId]
            .timeSeries
        : [];
    return timeSeries;
  },
);

export const getSelectedPortfolioTimeSeriesFetched = createSelector(
  [state, selectedContactGuid, selectedPortfolioId],
  (state, contactGuid, portfolioId) => {
    const timeSeriesFetched =
      !!contactGuid &&
      !!portfolioId &&
      state.portfolios.selectedPortfolioData[contactGuid] !== undefined &&
      state.portfolios.selectedPortfolioData[contactGuid][portfolioId] !==
        undefined &&
      state.portfolios.selectedPortfolioData[contactGuid][portfolioId]
        .timeSeriesFetched;
    return timeSeriesFetched;
  },
);

export const getSelectedPortfolioTimeSeriesError = createSelector(
  [state, selectedContactGuid, selectedPortfolioId],
  (state, contactGuid, portfolioId) => {
    const timeSeriesError =
      !!contactGuid &&
      !!portfolioId &&
      state.portfolios.selectedPortfolioData[contactGuid] !== undefined &&
      state.portfolios.selectedPortfolioData[contactGuid][portfolioId] !==
        undefined &&
      state.portfolios.selectedPortfolioData[contactGuid][portfolioId]
        .timeSeriesError;
    return timeSeriesError;
  },
);

export const getSelectedPortfolioTransactions = createSelector(
  [state, selectedContactGuid, selectedPortfolioId],
  (state, contactGuid, portfolioId) => {
    const transactions =
      !!contactGuid &&
      !!portfolioId &&
      state.portfolios.selectedPortfolioData[contactGuid] !== undefined &&
      state.portfolios.selectedPortfolioData[contactGuid][portfolioId] !==
        undefined
        ? state.portfolios.selectedPortfolioData[contactGuid][portfolioId]
            .transactions
        : [];
    return transactions;
  },
);

export const getSelectedPortfolioTransactionsFetched = createSelector(
  [state, selectedContactGuid, selectedPortfolioId],
  (state, contactGuid, portfolioId) => {
    const transactionsFetched =
      !!contactGuid &&
      !!portfolioId &&
      state.portfolios.selectedPortfolioData[contactGuid] !== undefined &&
      state.portfolios.selectedPortfolioData[contactGuid][portfolioId] !==
        undefined &&
      state.portfolios.selectedPortfolioData[contactGuid][portfolioId]
        .transactionsFetched;
    return transactionsFetched;
  },
);

export const getSelectedPortfolioTransactionsError = createSelector(
  [state, selectedContactGuid, selectedPortfolioId],
  (state, contactGuid, portfolioId) => {
    const transactionsError =
      !!contactGuid &&
      !!portfolioId &&
      state.portfolios.selectedPortfolioData[contactGuid] !== undefined &&
      state.portfolios.selectedPortfolioData[contactGuid][portfolioId] !==
        undefined &&
      state.portfolios.selectedPortfolioData[contactGuid][portfolioId]
        .timeSeriesError;
    return transactionsError;
  },
);

export const selectIndices = (state: RootState) => state.portfolios.indices;

export const selectIndicesFetched = (state: RootState) =>
  state.portfolios.indicesFetched;

export const selectIndicesError = (state: RootState) =>
  state.portfolios.indicesError;

export default portfoliosSlice.reducer;
